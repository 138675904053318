@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.select__control {
  border: 1px solid #d4d4d8;
  border-radius: 4px;
  background: #ffffff;
  padding: 6px 0.75rem;
  cursor: pointer !important;
  font-size: 16px;
}

.select__control:focus {
  box-shadow: 0 0 #0000, 0 0 0 calc(4px + 0px) rgba(91, 186, 185, 0.5), 0 0 #0000 !important;
}

.select__control:active {
  box-shadow: 0 0 #0000, 0 0 0 calc(4px + 0px) rgba(91, 186, 185, 0.5), 0 0 #0000 !important;
}

.select__control:hover {
  border: 1px solid #5bbab9 !important;
}

.select__value-container {
  padding: 0 !important;
}

.rbc-row-content {
  z-index: 0 !important;
}

@font-face {
  font-family: 'FatFrank';
  src: url('fonts/helveticaneue/FatFrank\ Heavy\ Regular.ttf');
}

.assessment-modal .close-icon {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 100;
}

.assessment-modal .modal-container {
  padding: 0;
}

.assessment-modal .modal-container > div {
  padding: 16px;
  height: 100vh;
  margin: 0;
  background: #f4f4f5;
}

@media only screen and (max-width: 768px) {
  .question-container .close-icon-wrapper {
    top: -27px;
    right: 1px;
  }
}

#notficationList::-webkit-scrollbar,
#MainContainer::-webkit-scrollbar {
  width: 10px;
}

#notficationList::-webkit-scrollbar-thumb,
#MainContainer::-webkit-scrollbar-thumb {
  background-color: #d4d4d8;
  border-radius: 100px;
  height: 100px;
}
